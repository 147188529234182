import React, { ReactNode, useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill-new';
import { styled, SxProps } from '@mui/material/styles';
import useUpload from 'hooks/useUpload';
import EditorToolbar, { formats } from './EditorToolbar';
import { QuillEditorStyle } from './EditorStyle';
import VideoDialog from './VideoDialog';
import { Resizer } from './Resizer';

export const EditorStyle = styled(QuillEditorStyle)(({ theme }) => ({
  overflow: 'visible',
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  border: `solid 1px ${theme.palette.grey[500_32]}`,
  '& .ql-container.ql-snow': {
    borderColor: 'transparent',
    ...theme.typography.body1,
    fontFamily: theme.typography.fontFamily,
    /**
     * 아래 세 파일 모두 같은 스타일이 적용되어야 합니다.
     * https://github.com/carpenstreet/pertnerhub/blob/main/src/common/EditorV2/index.tsx
     * https://github.com/carpenstreet/acon-admin/blob/main/src/components/editor/index.tsx
     * https://github.com/carpenstreet/acon3d/blob/main/components/_new_components/Pages/product/contents/detail/contents/ProductDetailContents.styles.ts
     *
     * 논의 내용
     * https://acontainer.slack.com/archives/C07FQ0UG5K6/p1728552465984609
     */
    '& h1': {
      fontSize: '3.25em',
    },
    '& h2': {
      fontSize: '2.5em',
    },
    '& h3': {
      fontSize: '2em',
    },
    '& h4': {
      fontSize: '1.5em',
    },
    '& h5': {
      fontSize: '1.17em',
    },
  },
  '& .ql-editor': {
    minHeight: 200,
    '&.ql-blank::before': {
      fontStyle: 'normal',
      color: theme.palette.text.disabled,
    },
    '& pre.ql-syntax': {
      ...theme.typography.body2,
      padding: theme.spacing(2),
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.grey[900],
    },
  },
  '& .ql-toolbar.ql-snow .ql-color-picker>.ql-picker-options': {
    width: 'max-content',
    maxWidth: '152px',
  },
  /** [ul의 bullet에 있는 문제](https://github.com/slab/quill/issues/4437)를 감추기 위해 추가 */
  '& ol > li::before': {
    scale: 0,
  },
}));

export interface Props extends ReactQuill.ReactQuillProps {
  id?: string;
  error?: boolean;
  simple?: boolean;
  isProductWrite?: boolean;
  helperText?: ReactNode;
  sx?: SxProps;
  defaultHeight?: number;
}

export function EditorV2({ id = 'minimal-quill', error, value, onChange, simple = false, isProductWrite = false, helperText, sx, defaultHeight, ...other }: Props) {
  const storageUrl = process.env.NEXT_PUBLIC_STORAGE_URL || 'https://storage.acon3d.com';
  const editorRef = useRef<ReactQuill>(null);
  const { upload } = useUpload('inquiry');
  const [isLoad, setIsLoad] = useState(false);
  const [isShowVideoDialog, setIsShowVideoDialog] = useState(false);
  const modules = {
    toolbar: {
      container: `#${id}`,
    },
    history: {
      delay: 500,
      maxStack: 100,
      userOnly: true,
    },
    syntax: true,
    clipboard: {
      matchVisual: false,
    },
  };

  const onClickFile = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    input.onchange = () => {
      if (!input.files) return;
      onChangeUploadFile(input.files);
    };
  };

  const onChangeUploadFile = async (files: FileList) => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const result = await upload(file, (progress) => {});
      if (!result) return;
      setImage(`${storageUrl}/${result.azureStoragePath}`);
    }
  };

  const setImage = (url: string) => {
    const editor = editorRef.current?.editor || null;
    if (!editor) return;
    const range = editor.getSelection(true);
    const { index } = range;
    editor.insertEmbed(index || 0, 'image', url);
  };

  const insertVideo = (url: string | boolean) => {
    const editor = editorRef.current?.editor || null;
    if (!editor) return false;
    if (!url) {
      alert('동영상을 불러올 수 없습니다.');
      return false;
    }

    const range = editor.getSelection(true);
    const { index } = range;

    editor.insertEmbed(index, 'video', url);
    return true;
  };

  useEffect(() => {
    if (editorRef.current && editorRef.current.editor && !isLoad) {
      const toolbar = editorRef.current.editor.getModule('toolbar') as any;
      toolbar.addHandler('image', onClickFile);
      toolbar.addHandler('video', () => setIsShowVideoDialog(true));
      setIsLoad(true);
    }
  }, [editorRef.current]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const { Quill } = require('react-quill-new');
      // eslint-disable-next-line prefer-const
      let Video = Quill.import('formats/video');

      class VideoBlot extends Video {
        static create(value: any) {
          // eslint-disable-next-line prefer-const
          let node = super.create(value);
          node.setAttribute('src', value);
          node.setAttribute('frameborder', '0');
          node.setAttribute('allowfullscreen', true);
          node.setAttribute('width', '100%');
          node.setAttribute('height', '306px');
          return node;
        }
        static formats(node: any) {
          return {
            src: node.getAttribute('src'),
            width: node.getAttribute('width'),
            height: node.getAttribute('height'),
          };
        }
      }

      VideoBlot.blotName = 'video';
      VideoBlot.tagName = 'iframe';
      Quill.debug('error');
      Quill.register(VideoBlot);
    }
  }, []);

  const [height, setHeight] = useState(defaultHeight || +sessionStorage.getItem('editorHeight') || 500);
  const resize = React.useCallback(
    (adjustHeight: number) =>
      setHeight((prev) => {
        const newHeight = prev + adjustHeight;
        sessionStorage.setItem('editorHeight', newHeight.toString());
        return newHeight;
      }),
    [],
  );

  return (
    <>
      <VideoDialog isOpen={isShowVideoDialog} onClose={() => setIsShowVideoDialog(false)} insertVideo={insertVideo} />
      <div>
        <EditorStyle
          sx={{
            ...(error && {
              border: (theme) => `solid 1px ${theme.palette.error.main}`,
            }),
            ...sx,
          }}
        >
          <EditorToolbar id={id} isSimple={simple} isProductWrite={isProductWrite} />
          <ReactQuill
            ref={editorRef}
            value={value}
            onChange={onChange}
            modules={modules}
            formats={formats}
            placeholder="Write something awesome..."
            style={{ height }}
            {...other}
          />
          <Resizer resize={resize} />
        </EditorStyle>
        {helperText && helperText}
      </div>
    </>
  );
}
